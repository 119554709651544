import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from './request'
import './style.less'
import directives from './directive'

Vue.config.productionTip = false

Vue.use(request)

for (const directive in directives) {
  Vue.directive(directive, directives[directive])
}


new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
