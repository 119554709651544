<template>
  <div class="home-wrap">
    <div class="home-bg">
      <div class="home">
        <div class="logo-wrap">
          <img src="@/assets/icon/logo.png" style="height: 40px" alt="ELU 中能坤域">
        </div>
        <div class="slogn tc">
          <img src="@/assets/icon/slogn.png" style="height: 387px;" alt="connect to the future">
        </div>
        <div class="content">
          <div class="flex tab">
            <div class="flex" :class="cur === 0 && 'cur'" @mouseenter="cur = 0">
              <img src="@/assets/icon/icon1.png" alt="关于我们">
              关于我们
              <div class="dn">ELU是一家以技术驱动、科技引领的创新型人工智能科技企业，致力于将人工智能、机器人等前沿技术应用于绿色能源等领域，ELU深耕AI机器人和新能源电力两大万亿级交汇的行业赛道，以AI机器人作为新质生产力，配合全栈自研的超级能源引擎（HEE）驱动业务快速发展，加速推动城市智慧能源转型，助力实现国家“3060”战略目标。</div>
            </div>
            <div class="flex" :class="cur === 1 && 'cur'" @mouseenter="cur = 1">
              <img src="@/assets/icon/icon2.png" alt="关于我们">
              机器人
              <div class="dn">中能坤域致力于将前沿科技与能源行业深度融合，推出全球领先的智能能源机器人，采用先进的机器人技术和人工智能算法，打造能够在复杂的能源环境中自主作业，执行各种关键任务的能源机器人，实现能源行业与科技创新的巧妙结合。</div>
            </div>
            <div class="flex" :class="cur === 2 && 'cur'" @mouseenter="cur = 2">
              <img src="@/assets/icon/icon3.png" alt="关于我们">
              人工智能
              <div class="dn">我们推出全栈自研的场景化AI引擎——超级能源引擎（HEE），将AI深度融入能源管理和运营中，提供定制化智能解决方案。基于海量能源数据和前沿AI技术，HEE在多场景应用中显著提升效率，降本增效。同时，我们自主研发的边缘侧智能网关实现HEE的边缘化部署，并与IoT物联智控能力无缝耦合，实现智能化运营和管理。</div>
            </div>
            <div class="flex" :class="cur === 3 && 'cur'" @mouseenter="cur = 3">
              <img src="@/assets/icon/icon4.png" alt="关于我们">
              新能源
              <div class="dn">中能坤域深耕新能源领域，提供从投建到管理、运营的全链路服务。我们凭借丰富的经验和前瞻性的视角，为客户量身定制新能源场景化项目方案。无论是风电、光伏、氢能、储能，还是虚拟电厂、智慧能源管理、能源平台运维，我们都能提供全方位支持，确保项目的高效推进和顺利实施。</div>
            </div>
          </div>
          <div ref="scroll" class="scroll-wrap">
            <div v-resize="resize"><span id="typed"></span></div>
          </div>
        </div>
        <!-- <div class="tip"></div> -->
        <div class="new-wrap">
          <div class="flex tab">
            <div class="flex cur">
              <img src="@/assets/icon/icon1.png" alt="最新文章">
              最新文章
            </div>
          </div>
          <a v-for="item in list" :key="item.id" tag="div" :href="`/news/${item.id}`" target="_blank" :to="`/news/${item.id}`" class="flex new">
            <img class="cover" :src="`${item.picture}?x-oss-process=image/resize,m_fill,h_720,w_960/quality,q_90`">
            <div class="f1">
              <a class="title ellipsis">{{ item.title }}</a>
              <div class="desc">{{ item.intro }}</div>
              <div class="flex info">
                <div class="f1">{{ item.author }}<b v-if="item.tag"> | </b><span v-for="tag in formatTag(item.tag)" :key="tag" style="margin-right: 4px">{{ tag }}</span></div>
                <div class="tr">{{ item.publishTime }}</div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <img class="bg-icon1" src="@/assets/bg/bg_icon1.png">
      <img class="bg-icon2" src="@/assets/bg/bg_icon2.png">
    </div>
    <div class="footer">
      <div class="flex upper">
        <div class="f3">
          <img src="@/assets/icon/logo_bottom.png" alt="ELU" style="height: 25px">
        </div>
        <div class="f3">
          <b>总部地址</b>
          <p>中国杭州市余杭区</p>
          <p>阿里巴巴数字生态创新园</p>
        </div>
        <div class="f3">
          <b>联系咨询</b>
          <p>如需咨询相关事宜</p>
          <p>请发邮件至：info@elu-energy.com</p>
        </div>
        <div class="f2">
          <b>关注我们</b>
          <div class="flex channel">
            <div class="flex">
              <img style="height: 18px" src="@/assets/icon/wchart.png">
            </div>
            <div class="flex">
              <img style="height: 18px" src="@/assets/icon/ticktock.png">
            </div>
            <div class="qrcode">
              <img style="width: 36px" src="@/assets/icon/elu-channel.png">
            </div>
          </div>
        </div>
      </div>
      <div class="lower">
        <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-right: 48px">ICP备案：浙ICP备2024056153号-1</a>
        <span>Copyright © 2024 中能坤域能源控股（浙江）有限公司 ELU  Energy Holding Group (ZheJiang) Co., Ltd.</span>
      </div>
    </div>
  </div>
</template>
<script>
import Typed from 'typed.js'
export default {
  name: 'HomeView',
  data() {
    return {
      typeIns: null,
      cur: 0,
      list: []
    }
  },
  watch: {
    cur(val) {
      this.initTyped(val)
    }
  },
  created() {
    this.getNewsList()
  },
  mounted() {
    this.initTyped()
  },
  methods: {
    getNewsList() {
      this.$http.get('/system/article/list').then(res => {
        this.list = res.data.data || []
      })
    },
    formatTag(tagStr) {
      if (tagStr) {
        const str = tagStr.replace(/，/g,',')
        return str.split(',') || []
      }
      return []
    },
    resize() {
      let e = this.$refs.scroll
      e.scrollTop = e.scrollHeight - e.clientHeight
    },
    initTyped(cur) {
      if (this.typeIns) {
        this.typeIns.destroy()
      }
      let strArr = [
        // '中能坤域：^200全球领先的人工智能科技企业^500 <br>中能坤域能源控股（浙江）有限公司（简称：中能坤域）^500，英文名：ELU Energy（简称：ELU）^500是一家集团化运营^200、全球领先的人工智能科技企业^500，致力于通过数字化引领和技术驱动^500，提供全球顶尖多场景^200、多行业的软件和硬件产品^500，涵盖人工智能^200、机器人^200、新能源^200、智能硬件等综合产品与运营服务。^500ELU拥有一支世界级的顶尖团队^500，成员来自阿里巴巴集团^200、达摩院^200、华为集团等全球500强企业。^500公司积极响应国家“双碳”战略^300，加速推动数字智能和新能源这两大万亿级产业的创新融合与协同发展^500，以新质生产力推动国家高质量发展。',
        'ELU是一家以技术驱动^200、科技引领的创新型人工智能科技企业^500，致力于将人工智能^200、机器人^200等前沿技术应用于绿色能源等领域^500，ELU深耕AI机器人和新能源电力两大万亿级交汇的行业赛道^500，以AI机器人作为新质生产力^300，配合全栈自研的超级能源引擎（HEE）驱动业务快速发展^500，加速推动城市智慧能源转型^300，助力实现国家“3060”战略目标。',
        '中能坤域致力于将前沿科技与能源行业深度融合^200，推出全球领先的智能能源机器人^500，采用先进的机器人技术和人工智能算法^200，打造能够在复杂的能源环境中自主作业^200，执行各种关键任务的能源机器人^500，实现能源行业与科技创新的巧妙结合。',
        '我们推出全栈自研的场景化AI引擎——超级能源引擎（HEE）^200，将AI深度融入能源管理和运营中^200，提供定制化智能解决方案。^500基于海量能源数据和前沿AI技术，^200HEE在多场景应用中显著提升效率，降本增效。^500同时，我们自主研发的边缘侧智能网关实现HEE的边缘化部署^200，并与IoT物联智控能力无缝耦合^200，实现智能化运营和管理。',
        '中能坤域深耕新能源领域^200，提供从投建到管理^200、运营的全链路服务。^500我们凭借丰富的经验和前瞻性的视角^200，为客户量身定制新能源场景化项目方案。^500无论是风电^200、光伏^200、氢能^200、储能^200，还是虚拟电厂^200、智慧能源管理^200、能源平台运维^500，我们都能提供全方位支持^500，确保项目的高效推进和顺利实施。',
        // '中能坤域引领创新^200，推出旗舰级智能硬件产品。^500我们的浸式液冷储能一体柜凭借超高效能^200、长寿命设计^200以及卓越的安全性能^200，革新了储能行业标准。^500此外，^200我们还推出了智能微逆^200、光电建材^200、边缘计算一体机及充放电监测终端等全方位智能硬件矩阵^500，为企业及个人用户提供定制化^200、高效率的能源管理解决方案。^500边缘侧智能网关通过本地化部署减少数据传输量^200，提高响应速度^200，并可预留5G通讯模块^200，实现与云端大数据的互联互通和实时协同决策。'
      ]
      this.typeIns = new Typed('#typed', {
        strings: [strArr[cur || 0]],
        typeSpeed: 64
      })
    }
  }
}
</script>
<style lang="less" scoped>
.dn {
  display: none;
}
.home-wrap {
  width: 100%;
  min-width: 1200px;
  min-height: 1225px;
}
.logo-wrap {
  width: 1100px;
  margin: 0 auto;
}
.home-bg {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: calc(100% - 190px);
  background: #F5F8FF url('../assets/bg/all_bg.png') no-repeat scroll top / 100% auto;
  .bg-icon1, .bg-icon2 {
    width: 480px;
    position: absolute;
    z-index: 1;
  }
  .bg-icon1 {
    top: 320px;
    left: -240px;
  }
  .bg-icon2 {
    top: -130px;
    right: -240px;
  }
}
.home {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  width: 1200px;
  padding-top: 50px;
}
.slogn {
  margin: 32px auto;
  margin-top: 0px;
}
.content {
  width: 1100px;
  background-color: #FFF;
  height: 250px;
  border-radius: 20px;
  margin: 0 auto;
  padding: 30px 70px;
  font-size: 16px;
  line-height: 32px;
  user-select: none;
  .tab {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;
    justify-content: space-between;
    .flex {
      opacity: .4;
      // color: #A1A6B2;
      transition: all ease-in .1s;
    }
    img {
      height: 35px;
      margin-right: 8px;
    }
  }
  .cur {
    opacity: 1 !important;
    transform: scale(1.1);
  }
  .scroll-wrap {
    height: 130px;
    overflow: hidden;
  }
  #typed {
    color: #8987A1;
  }
}
.tip {
  width: 700px;
  height: 65px;
  margin: 32px auto;
  background: url('../assets/bg/wait_bg.png') no-repeat scroll center / 100%;
}
.footer {
  background: #000;
  height: 190px;
  width: 100%;
  padding-top: 10px;
}
.upper {
  height: 120px;
  width: 900px;
  margin: 0 auto;
  border-bottom: 1px solid #FFFFFF32;
  padding-top: 20px;
  align-items: flex-start;
  color: #FFF;
  .f2 {
    flex: 2;
  }
  .f3 {
    flex: 3;
  }
  b {
    line-height: 30px;
    font-size: 14px;
    margin-bottom: 2px;
  }
  p {
    color: #FFFFFF64;
    font-size: 12px;
    line-height: 24px;
  }
  .channel {
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
    .flex {
      height: 36px;
      width: 36px !important;
      border: 1px solid #999999;
      border-radius: 40px;
      justify-content: center;
    }
  }
  .qrcode {
    position: relative;
    transition: all ease-in .5s;
  }
  // .qrcode:hover {
  //   scale: 5;
  //   right: 64px;
  //   bottom: 64px;
  // }
}
.lower {
  height: 59px;
  width: 900px;
  margin: 0 auto;
  line-height: 58px;
  text-align: center;
  font-size: 12px;
  color: #FFFFFF64;
}
.new-wrap {
  width: 1100px;
  background-color: #FFF;
  border-radius: 20px;
  margin: 48px auto;
  padding: 30px 70px;
  font-size: 14px;
  .tab {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 24px;
    justify-content: space-between;
    img {
      height: 35px;
      margin-right: 8px;
    }
  }
}
.new {
  user-select: auto;
  width: 960px;
  margin: 32px auto;
  height: 180px;
  .cover {
    height: 180px;
    width: 240px;
    margin-right: 20px;
    cursor: pointer;
  }
  .title {
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 10px;
    cursor: pointer;
    display: block;
  }
  .desc {
    height: 110px;
    font-size: 14px;
    line-height: 26px;
    color: #7F7F7F;
    overflow: hidden;
  }
  .info {
    color: #ADADAD;
    font-size: 14px;
  }
}
</style>