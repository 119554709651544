import Vue from "vue"
import axios from 'axios'

const service = axios.create({
  baseURL: '/api',
  timeout: 10000
})

if (!Vue.prototype.$http) {
  Vue.prototype.$http = service
}

export default async () => {
  Vue.prototype.$http.interceptors.request.use(config => {
      config.headers['Accept-Language'] = 'zh-CN'
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
}
