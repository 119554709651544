import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from './views/HomeView.vue'
import MobileView from './views/MobileView.vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: HomeView
    },
    {
      path: '/m',
      name: 'MobileHome',
      component: MobileView
    },
    {
      path: '/news/:id',
      name: 'NewsDetail',
      component: () => import('@/views/News')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const userAgent = navigator.userAgent
  const drod = userAgent.match(/(Android);?[\s/]+([\d.]+)?/)
  const ios = userAgent.match(/(iPhone\sOS)\s([\d_]+)/)
  const isMobile = drod || ios
  if (to.name === 'Home') {
    return isMobile ? next('/m') : next()
  } else if (to.name === 'MobileHome') {
    return isMobile ? next() : next('/')
  } else {
    next()
  }
})

export default router